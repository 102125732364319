import React from "react";
import { Nav } from "rsuite";
import {
  RoomTabContent,
  FerryTabContent,
  ServiceTabContent,
} from "./offer-search-tabs/index";

export const TAB_KEY = {
  ROOM: "tourism-accommodation/room",
  FERRY: "tourism-ferry/ferry",
  SERVICE: "tourism-service/service",
};
export const TABS = {
  [TAB_KEY.ROOM]: {
    title: "Unterkünfte",
    component: RoomTabContent,
  },
  [TAB_KEY.FERRY]: {
    title: "Fähren",
    component: FerryTabContent,
  },
  [TAB_KEY.SERVICE]: {
    title: "NL",
    component: ServiceTabContent,
  },
};

export const OfferSearchTabs = ({ activeSearchTab, switchTab }) => {
  return (
    <div>
      <Nav
        appearance="subtle"
        activeKey={activeSearchTab}
        onSelect={switchTab}
        style={{ marginBottom: 10 }}
      >
        {Object.keys(TABS).map((tabKey) => (
          <Nav.Item key={tabKey} eventKey={tabKey}>
            {TABS[tabKey].title}
          </Nav.Item>
        ))}
      </Nav>
    </div>
  );
};
