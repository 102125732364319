import {
  InputGroup,
  Input,
  Icon,
  Checkbox,
  SelectPicker,
  InputNumber,
} from "rsuite";
import { RADIUS_PICKER_VALUES } from "../../constants/index";
import { ChoiceElement } from "../../../../choice/react-choice-element";
import { GoogleOfferCityAutoComplete } from "../offer-city-autocomplete";
import { DateSelect } from "../../../../form/rsuite-form";
import { withGMapOptions } from "../../helper/gmap-context";
import { Foldable } from "../offer-foldable";

const CityAutoComplete = withGMapOptions(GoogleOfferCityAutoComplete);

export const ServiceTabContent = ({
  filters,
  searchFreeText,
  setSearchFreeText,
  setFilter,
  sortOptions,
}) => (
  <>
    <div className="col-xs-4">
      <div className="form-group">
        <label>Leistungscharakter</label>
        <ChoiceElement
          config={{
            modelId: "tourism-service/service-character",
          }}
          placeholder="Wähle..."
          onChange={(value) => {
            setFilter("serviceCharacter", value ? { $eq: value } : null);
          }}
        />
      </div>
    </div>

    <div className="col-xs-4">
      <div className="form-group">
        <label>Leistungstyp</label>
        <ChoiceElement
          config={{
            modelId: "tourism-service/service-category",
          }}
          placeholder="Wähle..."
          onChange={(value) => {
            setFilter("category", value ? { $eq: value } : null);
          }}
        />
      </div>
    </div>
    <div className="radius-picker__container col-xs-4">
      <div className="form-group radius-picker">
        <label>Radius</label>
        <SelectPicker
          data={RADIUS_PICKER_VALUES}
          value={filters.radius}
          cleanable={false}
          onChange={(value) => {
            setFilter("radius", value ? value : null);
          }}
        />
      </div>
    </div>
    <div className="form-group col-xs-9">
      <InputGroup inside className="offer-search-parameters_input">
        <Input
          value={searchFreeText}
          onChange={setSearchFreeText}
          placeholder={"Suchbegriff"}
        />
        <InputGroup.Button>
          <Icon icon="search" />
        </InputGroup.Button>
      </InputGroup>
    </div>
    <div className="form-group col-xs-3">
      <SelectPicker
        data={sortOptions}
        cleanable={false}
        value={filters.sort}
        searchable={sortOptions.length > 10}
        onChange={(value) => {
          setFilter("sort", value ? value : null);
        }}
      />
    </div>
    <Foldable>
      <div className="col-xs-12">
        <div className="form-group">
          <label>Ort</label>
          <CityAutoComplete
            value={filters.city ?? ""}
            onSelect={(value) => {
              setFilter("city", value ? value : null);
            }}
          />
          <div className="col-xs-6" />
          <Checkbox
            className="col-xs-6"
            checked={!!filters["alongTheRoute"]}
            onChange={(_, checked) => setFilter("alongTheRoute", checked)}
          >
            Entlang der Route
          </Checkbox>
        </div>
      </div>
      <div className="form-group col-xs-6">
        <label>Dauer von</label>
        <DateSelect
          format="HH"
          ranges={[]}
          disabledHours={(hour) => hour < 0}
          onChange={(d) => {
            setFilter("timeFrom", d?.getHours() ?? null);
          }}
        />
      </div>
      <div className="form-group col-xs-6">
        <label>Dauer bis</label>
        <DateSelect
          format="HH"
          ranges={[]}
          disabledHours={(hour) => hour < 0}
          onChange={(d) => {
            setFilter("timeTo", d?.getHours() ?? null);
          }}
        />
      </div>
      <div className="col-xs-12 form-group">
        <label>Dauer (an aufeinanderfolgenden Tagen)</label>
        <InputGroup className="offer-planning__number-picker">
          <InputNumber
            className="offer-planning__number-picker__input"
            value={filters.duration ?? 1}
            onChange={(value) => setFilter("duration", Number(value))}
            max={999999}
            min={1}
          />
        </InputGroup>
      </div>
    </Foldable>
  </>
);
