import { InputGroup, Input, Icon, Checkbox, SelectPicker } from "rsuite";
import { ChoiceElement } from "../../../../choice/react-choice-element";
import { Foldable } from "../offer-foldable";

export const FerryTabContent = ({
  searchFreeText,
  setSearchFreeText,
  setFilter,
  isDefaultOccupancy,
  setIsDefaultOccupancy,
  filters,
  sortOptions,
}) => (
  <>
    <div className="form-group col-xs-9">
      <InputGroup inside className="offer-search-parameters_input">
        <Input
          value={searchFreeText}
          onChange={setSearchFreeText}
          placeholder={"Suchbegriff"}
        />
        <InputGroup.Button>
          <Icon icon="search" />
        </InputGroup.Button>
      </InputGroup>
    </div>
    <div className="form-group col-xs-3">
      <SelectPicker
        data={sortOptions}
        cleanable={false}
        value={filters.sort}
        searchable={sortOptions.length > 10}
        onChange={(value) => {
          setFilter("sort", value ? value : null);
        }}
      />
    </div>
    <Foldable>
      <div className="col-xs-6" />
      <div className="col-xs-6">
        <Checkbox
          checked={isDefaultOccupancy}
          onChange={(value, checked) => setIsDefaultOccupancy(checked)}
        >
          Relevant für Standard Kalkulation
        </Checkbox>
      </div>
      <div className="col-xs-6">
        <ChoiceElement
          config={{
            modelId: "tourism-room/occupancy-type",
          }}
          placeholder="Belegungstyp"
          onChange={(value) => {
            setFilter("srgCategory", value ? { $eq: value } : null);
          }}
        />
      </div>
      <div className="col-xs-6">
        <ChoiceElement
          config={{
            modelId: "tourism-room/fare",
          }}
          placeholder="Verpflegung"
          onChange={(value) => {
            setFilter("srgCategory", value ? { $eq: value } : null);
          }}
        />
      </div>
    </Foldable>
  </>
);
