import {
  InputGroup,
  Input,
  Icon,
  Checkbox,
  SelectPicker,
  InputNumber,
} from "rsuite";
import { RADIUS_PICKER_VALUES } from "../../constants/index";
import { ChoiceElement } from "../../../../choice/react-choice-element";
import { GoogleOfferCityAutoComplete } from "../offer-city-autocomplete";
import { withGMapOptions } from "../../helper/gmap-context";
import { Foldable } from "../offer-foldable";

const CityAutoComplete = withGMapOptions(GoogleOfferCityAutoComplete);

export const RoomTabContent = ({
  filters,
  searchFreeText,
  setSearchFreeText,
  setFilter,
  isDefaultOccupancy,
  setIsDefaultOccupancy,
  sortOptions,
}) => (
  <>
    <div className="col-xs-5">
      <div className="form-group">
        <label>Ort</label>
        <CityAutoComplete
          onSelect={(value) => {
            setFilter("city", value ? value : null);
          }}
          value={filters.city ?? ""}
        />
      </div>
    </div>
    <div className="col-xs-3">
      <div className="form-group radius-picker">
        <label>Radius</label>
        <SelectPicker
          cleanable={false}
          data={RADIUS_PICKER_VALUES}
          value={filters.radius}
          onChange={(value) => {
            setFilter("radius", value ? value : null);
          }}
        />
      </div>
    </div>
    <div className="col-xs-4">
      <div className="form-group">
        <label>SRG-Kategorie</label>
        <ChoiceElement
          config={{
            modelId: "service-reisen/srg-category",
          }}
          placeholder="Wähle..."
          onChange={(value) => {
            setFilter("srgCategory", value ? { $eq: value } : null);
          }}
        />
      </div>
    </div>
    <div className="form-group col-xs-9">
      <InputGroup inside className="offer-search-parameters_input">
        <Input
          value={searchFreeText}
          onChange={setSearchFreeText}
          placeholder={"Suchbegriff"}
        />
        <InputGroup.Button>
          <Icon icon="search" />
        </InputGroup.Button>
      </InputGroup>
    </div>
    <div className="form-group col-xs-3">
      <SelectPicker
        data={sortOptions}
        cleanable={false}
        value={filters.sort}
        searchable={sortOptions.length > 10}
        onChange={(value) => {
          setFilter("sort", value ? value : null);
        }}
      />
    </div>
    <Foldable>
      <div className="col-xs-12" />
      <div className="col-xs-12 form-group">
        <label>Dauer in Nächten</label>
        <InputGroup className="offer-planning__number-picker">
          <InputNumber
            className="offer-planning__number-picker__input"
            value={filters.duration ?? 1}
            onChange={(value) => setFilter("duration", Number(value))}
            max={999999}
            min={1}
          />
        </InputGroup>
      </div>
      <div className="col-xs-12 form-group">
        <ChoiceElement
          config={{
            modelId: "tourism-accommodation/hotel-equipment",
            multiple: true,
          }}
          placeholder="Hotelausstattung"
          onChange={(value) => {
            setFilter("equipments", value ? { $in: value } : null);
          }}
        />
      </div>
      <div className="col-xs-6">
        <Checkbox
          checked={isDefaultOccupancy}
          onChange={(value, checked) => setIsDefaultOccupancy(checked)}
        >
          Relevant für Standard Kalkulation
        </Checkbox>
      </div>
      <div className="col-xs-6">
        <ChoiceElement
          config={{
            modelId: "tourism-room/fare",
          }}
          placeholder="Verpflegung"
          onChange={(value) => {
            setFilter("fare", value ? { $eq: value } : null);
          }}
        />
      </div>
    </Foldable>
  </>
);
