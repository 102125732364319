export const VIEWS = {
    DAY: "timeGridDay",
    WEEK: "timeGrid",
    LONG_WEEK: "timeGridLong",
};

export const VIEW_DURATION_DAYS = {
    [VIEWS.DAY]: 1,
    [VIEWS.WEEK]: 4,
    [VIEWS.LONG_WEEK]: 10,
};

export const CALENDAR_LICENSE_KEY = "0599544215-fcs-1629197359";

//Todo load from core!
export const GOOGLE_MAP_KEY = "AIzaSyAEwP54JV7j6MKLjl5CXohPpJefz27fRKk";

export const CHECKBOX_LIST = [
    {
        name: "overwriteOfferCancellationDate",
        text: "Stornotermin überschreiben",
    },
    { name: "overwriteOptionDate", text: "Optionstermin überschreiben" },
];

export const eventCardClassNameMapping = {
    accommodation: ["calendar-wrapper--accommodation-event"],
    service: ["calendar-wrapper--service-event"],
};

export const eventCardClassNameMappingOptional = {
    accommodation: ["calendar-wrapper-optional-event-accommodation"],
    service: ["calendar-wrapper-optional-event-service"],
};

export const EVENT_VIEW = {
    SERVICE: "service",
    ACCOMMODATION: "accommodation",
    ACCOMMODATION_SHADOW: "accommodation_shadow",
    TRAVEL: "travel",
    HOME: "home",
    DAY_START: "day_start",
};

export const RADIUS_PICKER_VALUES = [
    {
        value: "100",
        label: "100km",
    },
    {
        value: "75",
        label: "75km",
    },
    {
        value: "50",
        label: "50km",
    },
    {
        value: "20",
        label: "20km",
    },
    {
        value: "15",
        label: "15km",
    },
    {
        value: "10",
        label: "10km",
    },
    {
        value: "5",
        label: "5km",
    },
];

export const SORT_OPTIONS = [
    {
        value: "relevancy",
        label: "Relevanz",
    },
    {
        value: "price",
        label: "Preis",
    },
    {
        value: "category",
        label: "Kategorie",
    },
];

//export const eventCardClassNameMappingUnknown = ["calendar-wrapper--default-event "]
