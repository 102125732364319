import React from "react";
import { Row, Col, Loader } from "rsuite";
import { OfferResultsCard } from "./offer-results-card";
import "./offer-search-form.less";

export const OfferSearchResults = ({
    isLoading,
    selectedSearchItem,
    searchResults = [],
    onSelectResult,
}) => {
    if (isLoading) return <Loader />;

    if (selectedSearchItem) {
        return (
            <Row gutter={0} className="offer-search-tabs__row">
                <OfferResultsCard item={selectedSearchItem} />
            </Row>
        );
    }

    if (searchResults.length > 0) {
        return (
            <Row gutter={0} className="offer-search-tabs__row">
                {searchResults.map((item, index) => (
                    <Col
                        xs={24}
                        sm={24}
                        md={24}
                        lg={24}
                        key={index}
                        onClick={() => onSelectResult(item)}
                    >
                        <OfferResultsCard item={item} />
                    </Col>
                ))}
            </Row>
        );
    }

    if (!isLoading && searchResults.length === 0) {
        return <p className="text-center">Keine Einträge gefunden</p>;
    }
};
